import React, { /* useContext, */ useState } from "react";

import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";

import request from "app/http";
import _ from "app/lang";
// Hide subscribe button #23860
// import { pageContext } from "app/page/ContextProvider";

import ActionFooter from "../ActionFooter";

const styles = (theme: Theme) => createStyles({
    root: {
        padding: theme.spacing(1, 2)
    }
});

interface Props extends WithStyles<typeof styles> {
    unreadCount: number;
    onMarkAllRead: () => void;
    trackingId: string;
}

function Subscription(props: Props) {
    const [subscribing, setSubscribing] = useState(false);
    // Hide subscribe button #23860
    // const context = useContext(pageContext);

    const handleClick = () => {
        setSubscribing(true);

        return request({url: "/apiv2/user/newsletter", method: "POST"}).then(() => {
            setSubscribing(false);
        });
    };

    // const showSubscribe = context.user.subscribe_newsletter;
    // Hide subscribe button #23860
    const showSubscribe = false;
    const showMarkAsRead = props.unreadCount > 0;

    // Must wrap Collapse in an outer div or else Chrome renders its wrong under
    // certain circumstances
    return (
        <div>
            <Collapse in={showSubscribe || showMarkAsRead}>
                <ActionFooter buttons alignRight>
                    {showMarkAsRead && (
                        <Button variant="text"
                            color="primary"
                            disabled={subscribing}
                            onClick={props.onMarkAllRead}
                            data-tracking-id={`${props.trackingId}-markAsRead`}
                        >
                            {_("Mark As Read")}
                        </Button>
                      )}
                    {showSubscribe && (
                        <Button variant="text"
                            color="primary"
                            disabled={subscribing}
                            onClick={handleClick}
                            data-tracking-id={`${props.trackingId}-subscribeToNewsletter`}
                        >
                            {_("Subscribe to Newsletter")}
                        </Button>
                      )}
                </ActionFooter>
            </Collapse>
        </div>
    );
}

export default withStyles(styles)(Subscription);
