import moment from "moment-timezone";
import React, { useEffect, useRef } from "react";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import RootRef from "@material-ui/core/RootRef";
import Typography from "@material-ui/core/Typography";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";

import Icon from "app/Icon";
import Mobiledoc from "app/Mobiledoc";
import _ from "app/lang";
import scrollIntoView from "shared/scrollIntoView";

import { Post } from "./blog";

const MAX_CONTENT_HEIGHT = 300;
const BLOG_WEBSITE_URL = "https://blog.ignitenet.com";

const styles = (theme: Theme) => createStyles({
    card: {
        backgroundColor: "transparent"
    },

    header: {
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#FEF9F3"
        }
    },

    unread: {
        backgroundColor: theme.colors.primary.main
    },

    title: {
        fontWeight: 600
    },

    content: {
        position: "relative",
        maxHeight: MAX_CONTENT_HEIGHT,
        overflowY: "hidden",

        "&::after": {
            content: "''",
            position: "absolute",
            bottom: 0,
            left: 0,
            height: 50,
            width: "100%",
            background: "linear-gradient(to top, white, rgba(255, 255, 255, 0))"
        }
    },

    buttonIcon: {
        marginLeft: theme.spacing(0.75)
    }
});

interface Props extends WithStyles<typeof styles> {
    post: Post;
    open: boolean;
    divider?: boolean;
    onToggle: () => void;
    trackingId: string;
}

function MessageCard(props: Props) {
    const { post, open, divider = true, onToggle, classes } = props;
    const { viewed = false } = post;

    const contentRef = useRef<HTMLDivElement>(null);
    const viewedRef = useRef(viewed);

    const avatar = (
        <Avatar className={viewedRef.current ? undefined : classes.unread}>
            <Icon id={viewed ? "drafts" : "mail"} />
        </Avatar>
    );

    const handleClick = () => {
        onToggle();
    };

    useEffect(function () {
        viewedRef.current = viewedRef.current || open;
    }, [open]);

    const handleTransitioned = () => {
        if (open && contentRef.current) {
            scrollIntoView(contentRef.current, {
                behavior: "smooth",
                block: "nearest",
                inline: "start"
            });
        }
    };

    const expandButtonTrackingId = `${props.trackingId}-expand`;
    const expandButton = (
        <IconButton onClick={handleClick} data-tracking-id={expandButtonTrackingId}>
            <Icon id={open ? "expand_less" : "expand_more"} />
        </IconButton>
    );

    const headerClasses = {
        root: classes.header,
        title: (viewedRef.current ? undefined : classes.title)
    };

    const formattedDate = moment(post.pub_date).format("LL");

    let postLink = post.link;

    if (postLink.indexOf("http") !== 0) {
        postLink = BLOG_WEBSITE_URL + postLink;
    }

    return (
        <RootRef rootRef={contentRef}>
            <Card square elevation={0} className={classes.card}>
                {divider ? <Divider /> : null}
                <CardHeader classes={headerClasses}
                    onClick={handleClick}
                    avatar={avatar}
                    action={expandButton}
                    title={post.title}
                    subheader={formattedDate}
                    data-tracking-id={expandButtonTrackingId}
                />
                <Collapse in={open} onEntered={handleTransitioned}>
                    <Divider />
                    <CardContent className={classes.content}>
                        <Mobiledoc content={post.mobiledoc}>
                            <Typography color="error">
                                {_("Post content not available at this time.")}
                            </Typography>
                        </Mobiledoc>
                    </CardContent>
                    <CardActions>
                        <Typography color="textSecondary">
                            <Button variant="text"
                                color={viewedRef.current ? "inherit" : "primary"}
                                href={postLink}
                                rel="noopener"
                                target="_blank"
                                onClick={() => viewedRef.current = true}
                                data-tracking-id={`${props.trackingId}-fullArticle`}
                            >
                                {_("Full article")}
                                <Icon id="open_in_new" small className={classes.buttonIcon} />
                            </Button>
                        </Typography>
                    </CardActions>
                </Collapse>
            </Card>
        </RootRef>
    );
}

export default withStyles(styles)(MessageCard);